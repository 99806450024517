// Sidebar
$sidebar-link-padding: 14px 15px;
$sidebar-icon-size: 14px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 10px 10px 10px 30px;
$sidebar-first-level-icon-size: 14px;

/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;
  z-index: 99;
  padding-top: 64px;
  background: $sidebar;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.scroll-sidebar {
  height: calc(100% - 20px);
  position: relative;
}

.ps__rail-y {
  z-index: 9;
}

.sidebar-nav {
  ul {
    .sidebar-item {
      width: $sidebar-width-full;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        opacity: 0.6;
        i {
          font-style: normal;
          width: 35px;
          line-height: 25px;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        .sidebar-badge {
          position: absolute;
          right: 30px;
          top: 17px;
        }
        &.active,
        &:hover {
          opacity: 1;
        }
      }
      &.active > .sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
      }
      /*******************
           First level
           ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        .sidebar-item.active > .sidebar-link {
          opacity: 1;
        }
        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
          }
        }
      }
    }
  }
}

// truncate
.hide-menu {
  white-space: normal;
  margin-right: 25px;
  width: 150px;
}

.overflow-inherit {
  overflow: inherit;
}

// small cap

.nav-small-cap {
  font-size: 12px;
  padding: $sidebar-link-padding;
  white-space: nowrap;
  display: flex;
  align-items: center;
  line-height: 30px;
  color: $sidebar-text;
  opacity: 0.3;
  text-transform: uppercase;
  i {
    line-height: 30px;
    margin: 0 5px;
  }
}

/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 26px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

/*******************
User Profile
******************/
.user-profile {
  padding: 15px;
  .user-content,
  .user-name,
  .user-email {
    color: $theme-light;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  a:after {
    display: none;
  }
}
.create-btn {
  height: 40px;
  background: $dark;
}


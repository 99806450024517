// In this scss you can overwrite all our scss, we suggested you to add your scss into this, so whenever the update comes it will not affect your code.
small.text-danger {
  display: none;
}

.ng-invalid.ng-touched ~ .invalid-feedback,
.ng-invalid.ng-touched ~ small.text-danger,
.ng-invalid.ng-dirty ~ .invalid-feedback,
.ng-invalid.ng-dirty.ng-touched ~ small.text-danger {
  display: block;
}

.btn-group {
  .p-button.p-button-icon-only.p-button-rounded {
    padding: 0;
    height: auto;
  }
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

.p-component:not(.p-button) {
  font-size: 14px !important;
  font-family: "Nunito Sans", sans-serif !important;
  border-color: #e9ecef;
}

.p-component.p-checkbox {
  width: 13px;
  height: 13px;

  .p-checkbox-box {
    width: 13px;
    height: 13px;
    .p-checkbox-icon {
      font-size: 8px;
    }
  }
}

.p-datatable-table {
  font-family: "Nunito Sans", sans-serif;

  .pi-sort-alt,
  .pi-sort-amount-up-alt,
  .pi-sort-amount-down {
    font-size: 6px;
  }

  .pi-sort-alt:before {
    content: '\e903 \A \e902';
  }

  .pi-sort-amount-up-alt:before {
    content: '\e903';
  }

  .pi-sort-amount-down:before {
    content: '\e902';
  }

  thead {
    th {
      font-size: 12px;
    }
  }
  tbody {
    td {
      font-size: 14px;
    }
  }
}

.p-button {
  background-color: $blue;
  border-color: $blue;
}

.p-dialog.delete-modal {
  width: 500px;
  max-width: 100%;
  margin: 0.5rem;

  .p-dialog-title {
    font-size: 1.25rem;
  }
}

.p-component.p-listbox {
  border: 1px solid #e9ecef;

  .p-listbox-header {
      &::after {
        content: "Select All";
      }
  }
}

p-listbox.ng-dirty.ng-invalid > .p-listbox {
  border-color: $red;
}

.p-tooltip.p-tooltip-top {
  .p-tooltip-text {
    background-color: #000;
    color: #fff;
  }
  .p-tooltip-arrow {
    border-top-color: #000;
  }
}

// dropdown
.p-dropdown {
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 5px 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #4f5467;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  .p-placeholder, .p-dropdown-label {
    padding: 0;
    font-size: 14px;
  }
  .p-dropdown-label.p-placeholder {
    color: #9f9e9d;
  }
  .p-dropdown-trigger {
    width: auto;
    .p-dropdown-trigger-icon {
      font-size: .6rem;
      color: #000;
      font-weight: 800;
    }
  }
  .pi-times {
    font-size: 14px;
  }
}

p-dropdown.ng-touched.ng-invalid {
  > .p-dropdown {
    border-color: #f62d51;
  }
}

.p-dropdown {
  .p-dropdown-panel {
    max-width: 100%;

    .p-dropdown-items .p-dropdown-item {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .p-dropdown-filter {
      width: 100%;
    }
  }
}

.p-calendar {
  height: 35px !important;
}

p-calendar.ng-touched.ng-invalid {
  .p-inputtext {
    border-color: #f62d51;
  }
}

ngb-modal-backdrop {
  z-index: 1050 !important;
}

.p-dropdown .p-dropdown-clear-icon {
  right: 1.2rem !important;
}
/*
Template Name: Admin Template
Author: Niravjoshi / Wrappixel
File: scss
*/
@use 'sass:math';

//(Required file)
@import "variable";

//Theme colors
@import "theme-colors/dark-theme";

// Import Bootstrap source files (Required file)
@import "../../../node_modules/bootstrap/scss/bootstrap";

//This is for the components (Required file)
@import "components";

//This is for the pages (if you are not using some pages you can remove that style from pages folder)
@import "pages";

//This is for the widgets (Required file)
@import "widgets/widgets";

//This is for the horizontal version if you are not using horizontal version you can remove this
@import "horizontal/horizontal";

//This is for the responsive (Required file)
@import "responsive";

//In This scss you can write your scss
@import "custom";

//This is for the icons (Required file)
@import "icons/simple-line-icons/css/simple-line-icons.css";
@import "icons/weather-icons/css/weather-icons.min.css";
@import "icons/themify-icons/themify-icons.css";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "icons/material-design-iconic-font/css/materialdesignicons.min.css";
@import "icons/crypto-icons/cryptocoins.css";

// this is for the perfect scrollbar
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,600,700,800');

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: white !important;
    border-color: transparent;
    border: none;
    color: #323130;
    margin: 0px;
    padding: 3px;
  }

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: lightgray !important;
    margin: 0px;
    padding: 3px;
}

.p-tabview .p-tabview-nav li {
    background: lightgray !important;
}

.p-tabview .p-tabview-nav {
    background: lightgray !important;
    width: fit-content;
    padding: 3px 3px 1px 3px;
}

.p-tabview .p-tabview-panels {
    padding: 0px;
}


.multilang-field-label {
  display: flex;
  align-items: self-end;
  justify-content: end;
}

.multilang-field-label-left {
  display: flex;
  align-items: end;
  justify-content: flex-start;
}

.multilang-field-area {
  display: flex;
  margin-top: 30px;
}

.multilang-field-area-right {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}

:root {
	--ck-sample-color-white: #ffffff;
	--ck-sample-color-green: #ffffff;
	--ck-sample-color-blue: #ffffff;
	--ck-sample-container-width: 1285px;
	--ck-sample-sidebar-width: 350px;
	// --ck-sample-editor-min-height: 400px;
	--ck-sample-editor-z-index: 10;
}

/* --------- EDITOR STYLES  ---------------------------------------------------------------------------------------- */

.editor__editable,
/* Classic build. */
main .ck-editor[role='application'] .ck.ck-content,
/* Decoupled document build. */
.ck.editor__editable[role='textbox'],
.ck.ck-editor__editable[role='textbox'],
/* Inline & Balloon build. */
.ck.editor[role='textbox'] {
	width: 100%;
	background: #fff;
	font-size: 1em;
	line-height: 1.6em;
}

.ck-md {
    .ck-editor__editable_inline {
      min-height: 180px !important;
      max-height: 500px !important;
    }
}

.ck-lg {
    .ck-editor__editable_inline {
      min-height: 380px !important;
      max-height: 500px !important;
  }
}

// :host ::ng-deep .ck-editor__editable_inline {
//   min-height: 500px !important;
// }

.ck.ck-editor__editable {
	background: #fff;
	border: 1px solid hsl(0, 0%, 70%);
	width: 100%;
}

/* Because of sidebar `position: relative`, Edge is overriding the outline of a focused editor. */
.ck.ck-editor__editable {
	position: relative;
	z-index: var(--ck-sample-editor-z-index);
}

.editor-container {
	display: flex;
	flex-direction: row;
    flex-wrap: nowrap;
    position: relative;
	width: 100%;
	justify-content: center;
}